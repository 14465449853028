//el希腊语
export const el = {
    //Μπάρα πλοήγησης
    'n1': 'Αρχική',
    'n2': 'Παραγγελία',
    'n3': 'Transaction',
    'n4': 'Ομάδα',
    'n5': 'μου',
    //κλάση προτροπής
    't1': 'Παρουσιάστηκε σφάλμα',
    't2': 'Η αντιγραφή επιτυχής',
    't3': 'Παρακαλώ συμπληρώστε πλήρως τις πληροφορίες',
    't4': 'Φόρτωση...',
    't5': 'Η σύνδεση επιτυχής',
    't6': 'Η βαθμολογία πρέπει να είναι μεγαλύτερη από 3 για υποβολή',
    't7': 'Η υποβολή επιτυχής',
    't8': 'Ανεπαρκές υπόλοιπο, δεν είναι δυνατή η λήψη',
    't9': 'Η υποβολή απέτυχε',
    't10': 'Συνδέστε πρώτα τη διεύθυνση και θα ανακατευθυνθείτε στην προσωπική κεντρική σελίδα',
    't11': 'Η διεύθυνση έχει δεσμευτεί, επικοινωνήστε με την εξυπηρέτηση πελατών για να την τροποποιήσετε',
    't12': 'Η τροποποίηση επιτυχής',
    't13': 'Η τροποποίηση απέτυχε',
    't14': 'Επικοινωνήστε με την εξυπηρέτηση πελατών για να αλλάξετε τον κωδικό πρόσβασής σας',
    't15': 'Σφάλμα μορφής κωδικού πρόσβασης',
    't16': 'Η απόσυρση ήταν επιτυχής',
    't17': 'Μέγιστες αναλήψεις',
    't18': 'Ελάχιστη ανάληψη',
    't19': 'Δύο κωδικοί πρόσβασης είναι ασυνεπείς',
    't20': 'Prompt',
    't21': 'Δεν μπορώ να πηδήξω προσωρινά',
    't22': 'Σφάλμα μορφής κωδικού πρόσβασης',
    't23': 'Η εγγραφή επιτυχής',
    't24': 'Παρακαλώ εισάγετε τον κωδικό περιοχής',
    't25': 'Όχι άλλο',
    //Εγγραφείτε
    'l1': 'Γλώσσα',
    'l2': 'Παρακαλώ εισάγετε τον αριθμό λογαριασμού σας',
    'l3': 'Παρακαλώ εισάγετε τον κωδικό πρόσβασης',
    'l4': 'θυμάμαι',
    'l5': 'Είσοδος',
    'l6': 'Μετάβαση στην εγγραφή',
    'l7': 'Χωρίς λογαριασμό',
    'l8': 'Αριθμός κινητού τηλεφώνου',
    'l9': 'ψευδώνυμο',
    'l10': 'password',
    'l11': 'Επιβεβαίωση κωδικού πρόσβασης',
    'l12': 'κωδικός πρόσκλησης',
    'l13': 'Εγγραφή',
    'l14': 'Έχω ήδη λογαριασμό',
    'l15': 'Μετάβαση για σύνδεση',
    'l16': 'Παρακαλώ εισάγετε έναν κωδικό πρόσβασης 6-16 ψηφίων',

    //βοήθεια
    'bz1': 'Βοήθεια',
    'bz2': 'Συχνές ερωτήσεις',
    'bz3': 'Κανόνες συναλλαγών',
    'bz4': 'Πώς να κάνετε ανάληψη χρημάτων',
    'bz5': 'Πώς να επαναφορτίσετε',
    'bz6': '1.Τι είναι το TiktokShop;',
    'bz7': 'TiktokShop είναι μια εταιρεία μάρκετινγκ και προώθησης που βοηθά τους εμπόρους του ηλεκτρονικού καταστήματος TiktokShop σε όλο τον κόσμο να λαμβάνουν περισσότερες πωλήσεις παραγγελιών και να αυξάνουν τα δεδομένα περιήγησης στο ηλεκτρονικό κατάστημα TiktokShop. Δεσμευόμαστε να καθιερώσουμε ένα τριμερές κερδοφόρο μοντέλο μάρκετινγκ και προώθησης μεταξύ του TiktokShop, των εμπόρων και των καταναλωτών. Σε συνδυασμό με την πιο πρόσφατη τεχνολογία blockchain P2P, οι καταναλωτές και οι έμποροι συνδέονται γρήγορα μέσω USDT (TRC20, ERC20). Οι εγγεγραμμένοι χρήστες μπορούν να λαμβάνουν προμήθειες για παραγγελίες, ενώ οι έμποροι μπορούν να αυξήσουν τα δεδομένα πωλήσεων του καταστήματός τους. Είναι το πιο πρόσφατο μοντέλο κέρδους στο μοντέλο blockchain του Διαδικτύου! ',
    'bz8': '2.Πώς λειτουργεί το TiktokShop;',
    'bz9': 'Τα καθημερινά σχόλια από το TiktokShop δείχνουν ότι προϊόντα που χρειάζονται βελτίωση πωλούνται στο TiktokShop. Οι χρήστες πρέπει μόνο να κάνουν εύκολα κλικ στην παραγγελία και το σύστημα δημιουργεί αυτόματα μια συνδρομή παραγγελίας. Οι χρήστες πληρώνουν το ποσό της παραγγελίας μέσω blockchain USDT και λαμβάνουν ημερήσιες προμήθειες. ',
    'bz10': '3. Γιατί οι τιμές του ίδιου νομίσματος είναι διαφορετικές σε δύο συναλλαγές; ',
    'bz11': 'Οι διαφορές τιμών προκαλούνται από όλους τους παράγοντες που δεν ευνοούν την ελεύθερη κυκλοφορία των νομισμάτων, συμπεριλαμβανομένης της ταχύτητας μεταφοράς νομισμάτων, των συνθηκών δικτύου, των περιορισμών πρόσβασης σε νομίσματα, της αναγνώρισης νομισμάτων από άτομα σε διαφορετικές περιοχές, ακόμη και των ζευγών συναλλαγών παρέχονται από τύπους ανταλλαγών, συναλλαγών κ.λπ. Επομένως, το ίδιο νόμισμα μπορεί να έχει διαφορές τιμών σε διαφορετικές συναλλαγές. ',
    'bz12': '4. Επενδυτικό κέρδος; ',
    'bz13': 'Όσο υψηλότερη είναι η τιμή του προϊόντος, τόσο υψηλότερο είναι το κέρδος της παραγγελίας. Ταυτόχρονα, το TiktokShop διανέμει τυχαία παραγγελίες μεγάλων προμηθειών. ',
    'bz14': 'Κάθε μέρα, το σύστημα δημιουργεί και διανέμει αυτόματα 60 παραγγελίες προϊόντων στους χρήστες. Οι χρήστες ολοκληρώνουν την πληρωμή για κάθε παραγγελία μέσω USDT και λαμβάνουν προμήθεια 0.6%. Οι μεγάλες παραγγελίες προμήθειας εκχωρούνται τυχαία. ',
    'bz15': 'Το USDT μπορεί να αποσυρθεί μετά από 60 παραγγελίες. (Εάν δεν ολοκληρωθούν 60 παραγγελίες, το σύστημα θα σταματήσει αυτόματα μέχρι τις υπόλοιπες παραγγελίες της ημέρας)',
    'bz16': 'Κάθε λογαριασμός μπορεί να δεσμευτεί μόνο σε έναν λογαριασμό USDT. Εάν παρουσιαστεί σφάλμα, επικοινωνήστε με την εξυπηρέτηση πελατών',
    'bz17': 'Μετά την ολοκλήρωση της παράδοσης των ημερήσιων παραγγελιών, μπορείτε να κάνετε ανάληψη χρημάτων κανονικά. Θα φτάσουν εντός 24 ωρών από την έναρξη της ανάληψης και δεν υπάρχει όριο ανάληψης',
    'bz18': 'Συνδέστε τη διεύθυνση ανάληψης (υποστηρίζει TRC-20) τη διεύθυνση USDT πριν την επαναφόρτιση. Μπορείτε να κάνετε κλικ στην Επαναφόρτιση στην APP ή την ιστοσελίδα και να επιλέξετε την αλυσίδα μπλοκ (TRC-20) που χρησιμοποιείτε για επαναφόρτιση. ',
    'bz19': "Δεν επιτρέπεται η επαναφόρτιση ή η μεταφορά χρημάτων μεταξύ σχετικών λογαριασμών. Πρόκειται για κακόβουλη συμπεριφορά κατάσχεσης παραγγελιών και επηρεάζει σοβαρά τους κανόνες λειτουργίας της πλατφόρμας. Εάν εντοπιστεί τέτοια συμπεριφορά, το σύστημα θα παγώσει αμέσως τον λογαριασμό και θα ακυρώσει την εργασία.",
    'bz20': 'Οι συσχετισμένοι λογαριασμοί πρέπει να ολοκληρώσουν τις εργασίες παραγγελίας στον λογαριασμό κάθε ημέρας για να μπορέσουν να συνεχίσουν να ενημερώνουν τις εργασίες παραγγελίας για την επόμενη ημέρα Εάν υπάρχει μια ημιτελής εργασία μεταξύ των συσχετισμένων λογαριασμών, η ενημέρωση των εργασιών παραγγελίας θα σταματήσει μέχρι να ολοκληρωθούν όλες οι εργασίες παραγγελίας',
    //κείμενο ενότητας
    //Κοινές λέξεις
    'ty1': 'ΟΚ',
    'ty2': 'Ακύρωση',
    'ty3': 'Υποβολή',
    'ty4': 'διεύθυνση',
    //εξώφυλλο
    's1': 'Σωρευτικό εισόδημα',
    's2': 'Υπηρεσία',
    's3': 'Σχετικά με εμάς',
    's4': 'Βοήθεια',
    's5': 'Συνεργάτης',
    's6': 'Η υπηρεσία μου',
    //Σειρά
    'd1': 'Ημιτελές',
    'd2': 'Ολοκληρώθηκε',
    'd3': 'Δεν υπάρχουν ακόμη δεδομένα',
    'd4': 'μονός αριθμός',
    'd5': 'Ώρα συναλλαγών',
    'd6': 'ποσό',
    'd7': 'Τρέχον αριθμός εργασιών',
    'd8': 'Αναμενόμενη επιστροφή',
    'd9': 'Χρειάζομαι περισσότερα',
    'd10': 'Βαθμολογία',
    //εμπορικές συναλλαγές
    'j1': 'Υπόλοιπο λογαριασμού',
    'j2': 'Εισαγωγή συναλλαγών',
    'j3': 'TiktokShop χρησιμοποιεί τα σχόλια του TiktokShop κάθε μέρα για να δείχνει τα προϊόντα που πωλούνται από το TiktokShop που χρειάζονται βελτίωση. Οι χρήστες χρειάζεται μόνο να κάνουν κλικ στην παραγγελία και το σύστημα θα δημιουργήσει αυτόματα μια υπογραφή παραγγελίας. Οι χρήστες πληρώνουν το ποσό της παραγγελίας μέσω του blockchain USDT και μπορούν να λαμβάνουν περισσότερο από 0,6% προμήθεια κάθε φορά και το σύστημα διανέμει τυχαία μεγάλες παραγγελίες ανταμοιβής. ',
    'j4': 'Έναρξη αντιστοίχισης',
    'j5': 'Τα σημερινά κέρδη',
    'j6': 'Ο αριθμός ολοκληρώθηκε',
    'j7': 'Συνολικός αριθμός εργασιών',
    //ομάδα
    'td1': 'Υπόλοιπο',
    'td2': 'προμήθεια',
    'td3': 'Λήψη',
    'td4': 'Συνολικός αριθμός ατόμων',
    'td5': 'χρήστης',
    'td6': 'συνεισφορά',
    'td7': 'Ποσότητα',
    'td8': 'κατάσταση',
    //Προσωπικό κέντρο
    'g1': 'Απόσυρση',
    'g2': 'Επαναφόρτιση',
    'g3': 'Πρόσκληση φίλων',
    'g4': 'Πρόσκληση τώρα',
    'g5': 'Για κάθε κέρδος που αποκομίζει ο φίλος σας μετά την εγγραφή, θα λαμβάνετε ένα αντίστοιχο ποσοστό προμήθειας',
    'g6': 'Προσωπικά στοιχεία',
    'g7': 'Στοιχεία κεφαλαίου',
    'g8': 'Recharge record',
    'g9': 'Αρχείο ανάληψης',
    'g10': 'Γλώσσα',
    'g11': 'Αποσύνδεση',
    'g12': 'Είστε βέβαιοι ότι θέλετε να αποσυνδεθείτε;',
    //Πληροφορίες χρήστη
    'yh1': 'Στοιχεία χρήστη',
    'yh2': 'Τροποποίηση',
    'yh3': 'ρύθμιση',
    'yh4': 'τηλέφωνο',
    'yh5': 'Είστε βέβαιοι ότι θέλετε να αποσυνδεθείτε;',
    'yh6': 'Αρχικός κωδικός πρόσβασης',
    'yh7': 'Νέος κωδικός πρόσβασης',
    //Λεπτομέριες
    'mx1': 'Συλλογή προμήθειας',
    'mx2': 'κέρδος',
    'mx3': 'Απορρίφθηκε η απόσυρση',
    'mx4': 'ώρα',
    'mx5': 'Επαναφόρτιση επιτυχής',
    'mx6': 'Απορρίφθηκε',
    'mx7': 'εκκρεμεί',
    'mx8': 'Διεύθυνση ανάληψης',
    'mx9': 'Διαθέσιμο υπόλοιπο',
    'mx10': 'Πραγματική άφιξη',
    'mx11': 'Παρακαλώ εισάγετε το ποσό ανάληψης',
    //ξεχειλίζω
    'cz1': 'Η διεύθυνση κατάθεσης υποστηρίζει μόνο ERC20 και το ελάχιστο ποσό κατάθεσης είναι 20 USDT',
    'cz2': 'Αντιγραφή',
    'cz3': 'Επιλογή νομίσματος Fiat',
    'cz4': 'πληρωμή',
    'cz5': 'Η διεύθυνση κατάθεσης υποστηρίζει μόνο TRC20-USDT και το ελάχιστο ποσό κατάθεσης είναι 20USDT',
    'cz6': 'Η διεύθυνση κατάθεσης υποστηρίζει μόνο ERC20 και το ελάχιστο ποσό κατάθεσης είναι 20 USDT',
    'cz1': 'Η διεύθυνση κατάθεσης υποστηρίζει μόνο ERC20 και το ελάχιστο ποσό κατάθεσης είναι 20 USDT',
    'cz1': 'Η διεύθυνση κατάθεσης υποστηρίζει μόνο ERC20 και το ελάχιστο ποσό κατάθεσης είναι 20 USDT',
    //περιοχή
    'qy1': 'κωδικός περιοχής',
    'yq1': 'Πρόσκληση φίλων',
    'yq2': 'κωδικός πρόσκλησης',
    'yq3': 'Προσκαλέστε φίλους και απολαύστε μεγάλα προνόμια',
    'yq4': 'Πηγαίνετε και προσκαλέστε',
}