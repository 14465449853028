export const sv = {
    "n1": "förstasida",
    "n2": "Beställa",
    "n3": "handel",
    "n4": "team",
    "n5": "mina",
    "t1": "Något gick fel",
    "t2": "Kopiera framgångsrikt",
    "t3": "Fyll i informationen i sin helhet",
    "t4": "belastning…",
    "t5": "Inloggning framgångsrikt",
    "t6": "Poängen måste vara större än 3 innan du skickar in",
    "t7": "Inlämning framgångsrik",
    "t8": "Otillräcklig balans, kan inte samlas in",
    "t9": "Inlämning misslyckades",
    "t10": "Bind först adressen och den personliga mittsidan kommer att omdirigeras till den personliga mittsidan.",
    "t11": "Adressen har varit bunden, vänligen kontakta kundtjänst för att ändra den",
    "t12": "Modifiering var framgångsrik",
    "t13": "Modifiering misslyckades",
    "t14": "Kontakta kundtjänst för att ändra ditt lösenord",
    "t15": "Lösenordsformatfel",
    "t16": "Framgångsrik tillbakadragande",
    "t17": "Upp för att ta ut kontanter",
    "t18": "Minimalt uttag",
    "t19": "Lösenorden är inkonsekventa två gånger",
    "t20": "antydan",
    "t21": "Kan inte hoppa för tillfället",
    "t22": "Lösenordsformatfel",
    "t23": "Registrerad framgångsrikt",
    "t24": "Ange riktnummer",
    "t25": "Inte mer än",
    "l1": "språk",
    "l2": "Ange ditt kontonummer",
    "l3": "Ange ditt lösenord",
    "l4": "komma ihåg",
    "l5": "Logga in",
    "l6": "Gå för att registrera sig",
    "l7": "Inget konto",
    "l8": "Telefonnummer",
    "l9": "Smicknamn",
    "l10": "lösenord",
    "l11": "Bekräfta lösenord",
    "l12": "Inbjudningskod",
    "l13": "register",
    "l14": "Har redan ett konto",
    "l15": "Gå för att logga in",
    "l16": "Ange ett 6-16-siffriga lösenord",
    "bz1": "hjälp",
    "bz2": "Vanliga frågor",
    "bz3": "Handelsregler",
    "bz4": "Hur man tar ut pengar",
    "bz5": "Hur man fyller på",
    "bz6": "1. Vad är Tiktokshop?",
    "bz7": "Tiktokshop är ett marknadsföringsföretag som hjälper Tiktokshop onlinebutikhandlare runt om i världen att få mer orderförsäljning och öka surfdata för Tiktokshop onlinebutik. Vi är engagerade i att skapa en treparts lönsam marknadsförings- och marknadsföringsmodell mellan Tiktokshop och köpmän och konsumenter. Genom att kombinera den senaste P2P -blockchain -tekniken ansluter snabbt konsumenter och köpmän via USDT (TRC20, ERC20). Registrerade användare kan få provisioner på beställningar, medan köpmän kan öka butikens försäljningsdata. Det är den senaste vinstmodellen i Internet Blockchain -modellen!",
    "bz8": "2. Hur fungerar Tiktokshop?",
    "bz9": "Genom Tiktokshops dagliga feedback visar det att när du säljer produkter som behöver förbättringar i Tiktokshop behöver användare bara klicka på beställningen och systemet automatiskt kommer att generera beställningsprenumerationer. Användare betalar orderbelopp genom blockchain USDT och får dagliga provisioner.",
    "bz10": "3. Varför finns det några skillnader i priserna på samma valuta i de två transaktionerna?",
    "bz11": "Prisskillnader orsakas av alla faktorer som inte bidrar till den fria cirkulationen av valutor, inklusive hastigheten för valutasöverföring, nätverksförhållanden, valutatillgångsbegränsningar, människors erkännande av valuta genom olika regioner och till och med de typer av transaktionspar som tillhandahålls av utbyten och transaktioner. Därför kan samma valuta skapa prisskillnader i olika transaktioner.",
    "bz12": "4. Investering Vinst?",
    "bz13": "Ju högre priset på det objekt du får, desto högre vinst får du i beställningen. Samtidigt tilldelar Tiktokshop slumpmässigt stora uppdragsorder.",
    "bz14": "Varje dag genererar och distribuerar systemet automatiskt 60 produktorder till användare. Stora uppdragsorder tilldelas slumpmässigt.",
    "bz15": "USDT kan ta ut kontanter efter 60 beställningar. (Om 60 beställningar inte är slutförda stannar systemet automatiskt till dagens återstående ordervolym)",
    "bz16": "Varje konto kan bara vara bundet till ett USDT -konto.",
    "bz17": "När du har slutfört leveransen av daglig order kan du ta ut kontanter normalt och anlända inom 24 timmar efter att du startat uttaget och det finns inga begränsningar för uttag.",
    "bz18": "Innan ladda upp, vänligen binda din uttagningsadress (stöder TRC-20) USDT-adress kan du klicka på appen eller webbsidan för att ladda och välja blockchain (TRC-20) du använder för laddning.",
    'bz19': "Återladdning eller överföring av pengar mellan relaterade konton är inte tillåtet. Detta är ett skadligt beteende som griper order och påverkar allvarligt plattformens driftregler. Om sådant beteende upptäcks kommer systemet omedelbart att frysa kontot och diskvalificera arbetet.",
    "bz20": "Det relaterade kontot måste slutföra beställningsuppgifterna i kontot varje dag innan de fortsätter att uppdatera beställningsuppgifterna nästa dag.",
    "ty1": "Säker",
    "ty2": "Avboka",
    "ty3": "överlämna",
    "ty4": "adress",
    "s1": "Kumulativ inkomst",
    "s2": "Tjäna",
    "s3": "om oss",
    "s4": "hjälp",
    "s5": "Anteckna",
    "s6": "Min tjänst",
    "d1": "Inte slutförd",
    "d2": "Avslutad",
    "d3": "Inga data än",
    "d4": "Enstaka nummer",
    "d5": "Handelstid",
    "d6": "Belopp",
    "d7": "Nuvarande antal uppgifter",
    "d8": "Förväntade intäkter",
    "d9": "Behov",
    "d10": "göra",
    "j1": "Kontosaldo",
    "j2": "Transaktionsintroduktion",
    "j3": "Tiktokshop använder Tiktokshop -feedback varje dag för att visa upp produkter som säljs av Tiktokshop som behöver förbättras. Användaren behöver bara klicka på beställningen och systemet genererar automatiskt ordersignaturen. Användare betalar orderbeloppet genom USDT -blockchain och får en provision på mer än 0,6% varje gång.",
    "j4": "Börja matcha",
    "j5": "Dagens intäkter",
    "j6": "Slutfört nummer",
    "j7": "Totalt antal uppgifter",
    "td1": "Balans",
    "td2": "provision",
    "td3": "få",
    "td4": "Totalt antal människor",
    "td5": "användare",
    "td6": "medverka",
    "td7": "kvantitet",
    "td8": "ange",
    "g1": "Uttag",
    "g2": "fylla på",
    "g3": "Bjud in vänner",
    "g4": "Bjud in nu",
    "g5": "Du kommer att få en motsvarande andel provision för varje vinst du tjänar efter att ha registrerat en vän",
    "g6": "personlig information",
    "g7": "Finansieringsuppgifter",
    "g8": "Laddningsrekord",
    "g9": "Uttagsrekord",
    "g10": "språk",
    "g11": "Logga ut",
    "g12": "Är du säker på att logga ut?",
    "yh1": "Användarinformation",
    "yh2": "Revidera",
    "yh3": "inrätta",
    "yh4": "Telefon",
    "yh6": "Originalt lösenord",
    "yh7": "Nytt lösenord",
    "mx1": "Ta emot provision",
    "mx2": "inkomst",
    "mx3": "Vägran",
    "mx4": "tid",
    "mx5": "Ladda framgångsrikt",
    "mx6": "Avvisad",
    "mx7": "I avvaktan på",
    "mx8": "Uttagadress",
    "mx9": "Tillgänglig saldo",
    "mx10": "Faktisk kvitto",
    "mx11": "Ange uttagsbeloppet",
    "cz2": "kopiera",
    "cz3": "Valutaval",
    "cz4": "Betala",
    "cz5": "Insättningsadressen stöder endast TRC20-USDT, och lägsta insättningsbelopp är 20USDT.",
    "cz6": "Insättningsadressen stöder endast ERC20, minsta insättningsbelopp är 20USDT",
    "cz1": "Insättningsadressen stöder endast ERC20, minsta insättningsbelopp är 20USDT",
    "qy1": "Riktnummer",
    "yq1": "Bjud in vänner",
    "yq2": "Inbjudningskod",
    "yq3": "Bjuda in vänner och njut av stora vinster",
    "yq4": "Gå och bjud in"
  }
  